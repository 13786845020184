import axios from "axios"

export default {
    getText: (callback) => {
        axios.get('https://v1.hitokoto.cn?c=d&c=h&c=i&c=k')
            .then(res => {
                callback(res.data);
            })
            .catch(() => {
                callback({});
            });
    }
}