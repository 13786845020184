<template>
  <div class="wrapper">
    <div class="dialog w-100vw h-85vh w-500px mt--15vh">
      <div class="content padding-15px border-radius-15px border-bottom-radius-15px relative" @click.stop>
        <div class="cut-off-rule">
          <div class="flex">
            <div class="flex-center">
              <van-image radius="3px" width="200" height="100"
                src="https://abj123.com/api/medias/query/7575d6c83ca141518779a524061adff4.png" />
            </div>
          </div>
        </div>

        <div class="cut-off-rule">
          <van-field v-model="remark" label="金额" placeholder="请填写充值金额" type="number" />
        </div>
        <div class="cut-off-rule">
          <van-button round style="width: 100%" color="linear-gradient(to left, #4160ab, #38bfeb )" @click="onPay">
            去付款充值
          </van-button>
        </div>
        <van-icon class="add-cursor absolute" name="cross" color="#9F9F9F" style="top: 15px; right: 15px;"
          @click="onClose" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, } from "vue";
import globals from "@/utils/globals";

export default {
  name: "TopUpDialogVue",
  emits: ['onClose'],
  setup(props, { emit }) {
    const state = reactive({
      remark: '',
      topUpId: 8,
      topUpAreaId: 37,
      topOptionId: 93,
    });
    const onPay = () => {
      if (state.remark == "") {
        globals.errorMsg("请输入充值金额");
        return;
      }
      let from =
      {
        product_id: state.topUpId,
        productoption_id: state.topOptionId,
        productoption_name: '自助充值 ',
        productarea_id: state.topUpAreaId,
        remark: state.remark,
        code: '',
      };
      globals.showLoading();
      globals.post('app-order/submit-order', from, (data) => {
        if (data.rc) {
          globals.successMsg("订单已提交");
          setTimeout(() => {
            globals.hideLoading();
            emit('onClose', data.data);
          }, 1000);
        }
      })
    };
    const onClose = () => {
      emit('onClose', null);
    };
    return {
      ...toRefs(state),
      onPay,
      onClose,
    };
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dialog {
  position: fixed;
}

.content {
  background-color: #fff;
  height: 100%;
  margin-top: -20px;
}

.cut-off-rule {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f6f6f6;
}

.tag-block {
  margin: 0 8px 8px 0;
  background-color: #f7f8fa;
  padding: 10px;
}

.tag-block:hover {
  cursor: pointer;
}

.on-tag-block {
  color: white !important;
  background-color: #38bfeb;
}

.border-radius-15px {
  border-radius: 15px;
}

.area-check {
  font-size: 0.7em;
  border-bottom: 1px solid;
  margin-left: 15px;
}

.area-check:hover {
  color: #00bcd4;
}
</style>