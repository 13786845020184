<template>
    <van-button @click="sendMsgCode" :disabled="loading || timer > 0" type="primary" class="verify-btn" size="small">
        <van-loading v-if="loading"></van-loading>
        <span v-else>{{
            timer >
            0 ? '重发 (' +
            timer + ')' : '发送验证码'
        }}
        </span>
    </van-button>
</template>

<script>
import { reactive, toRefs } from "vue";
import globals from "@/utils/globals";

export default {
    props: {
        phone: {
            type: String,
            default: "",
        },
        email: {
            type: String,
            default: "",
        }
    },
    setup(props) {
        const state = reactive({
            timer: 0,
            loading: false,
        });
        const sendMsgCode = () => {
            state.loading = true;
            if (props.phone != null && props.phone != '') {
                sendPhoneCode();
            }
            if (props.email != null && props.email != '') {
                sendEmailCode();
            }
        };
        const sendPhoneCode = () => {
            globals.post(
                "app-action/send-phone-verify-code", {
                phone: props.phone
            },
                (data) => {
                    if (data.rc) {
                        globals.successMsg(data.message);
                        state.timer = 60;
                        timerCor();
                    } else {
                        globals.errorMsg(data.message);
                    }
                    state.loading = false;
                }
            );
        };
        const sendEmailCode = () => {
            globals.post(
                "app-action/send-mail-verify-code", {
                email: props.email
            },
                (data) => {
                    if (data.rc) {
                        globals.successMsg(data.message);
                        state.timer = 60;
                        timerCor();
                    } else {
                        globals.errorMsg(data.message);
                    }
                    state.loading = false;
                }
            );
        };
        const timerCor = () => {
            state.timer--;
            if (state.timer > 0) {
                setTimeout(timerCor, 1000);
            }
        };
        return {
            ...toRefs(state),
            sendMsgCode,
        }
    },
}
</script>

<style>
.verify-btn {
    width: 120px;
    height: 44px;
    margin-left: 15px;
}
</style>