<template>
  <div class="h-60px" v-show="show">
    <nav class="flex-betw items-center h-60px bg-main-red shadow-md" style="position: fixed; width: 100%; z-index: 12">
      <a href="#" style="color: white;padding-top: 7px;" class="title-logo-offset" @click="goToPage('/')">
        <van-image :src="require('@/assets/title-logo.png')" width="250" :show-loading="false"></van-image>
      </a>
      <ul class="flex add-cursor" style="align-items: center;">
        <li @click="showOptionDialog = goToPage('/user/my-wallet')" class="flex-li wallet"
          style="margin: 0 7px; padding: 0 5px;" v-show="showWallet()">
          <span style="font-size: 0.8em;">￥</span>
          {{ (wallet || 0).toFixed(2) }}
        </li>
        <li :class="isActive('/') + ' hide-home-text'" @click="goToPage('/')">首页</li>
        <li :class="isActive('/user/my-accounts')" @click="goToPage('/user/my-accounts')">
          车票
        </li>
        <li :class="isActive('/user/share') + ' hide-home-text'" @click="goToPage('/user/share')">
          推广
        </li>
        <li :class="isActive('/user/support/new') + ' hide-home-text'" @click="to(3)">
          客服
        </li>
        <div @click="(showDropdown = !showDropdown)" style="position: relative; margin-left: 8px"
          v-clickOutside="onClickOutside">
          <div class="showDropdown default-avatar-gb flex-center">
            <van-image :src="$store.state.userinfo.head_path" round width="30" height="30 "
              style="border: 1px solid #35bcea;" />
          </div>
          <transition name="fade">
            <ul class="dropdown" v-show="showDropdown">
              <li class="dropdown-li" @click="to(0)">我的车票</li>
              <li class="dropdown-li" @click="to(5)">兑换服务</li>
              <li class="dropdown-li" @click="to(1)">我的订单</li>
              <li class="dropdown-li" @click="to(2)">我的推广</li>
              <li class="dropdown-li" @click="to(6)">我的钱包</li>
              <li class="dropdown-li" @click="to(4)">编辑资料</li>
              <li class="dropdown-li" @click="to(3)">联系客服</li>
              <li class="dropdown-li" @click="to(7)">通知设置</li>
              <li class="dropdown-li" @click="signout">退出登录</li>
            </ul>
          </transition>
        </div>
      </ul>
    </nav>

    <van-overlay :show="showOptionDialog" @click="showOptionDialog = false" z-index="99">
      <TopUpDialog v-if="showOptionDialog" @onClose="onSumbitOrder" />
    </van-overlay>
    <PayBox v-model="itemId" v-if="showPay" @onClose="onClosePay" />
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, watch } from "vue";
import globals from "@/utils/globals";
import TopUpDialog from "@/components/TopUpDialog.vue";
import { showDialog } from "vant";

export default {
  name: "NavBar",
  components: {
    TopUpDialog
  },
  setup() {
    const state = reactive({
      showDropdown: false,
      curNavName: "",
      urls: ['/user/my-accounts', '/user/order', '/user/share', '/user/support/new', '/user/useredit', '/user/giftCard', '/user/my-wallet', '/user/notice-config'],
      show: true,
      showOptionDialog: false,
      showPay: false,
      itemId: 0,
      isLogin: false,
      wallet: 0,
    });
    watch(
      () => globals.router.currentRoute.value.path,
      (newValue) => {
        state.curNavName = newValue;
        isActive(newValue);
        if (newValue == '/user/withdraw') {
          const windowWidth = window.innerWidth;
          if (windowWidth < 625) {
            state.show = false;
            return;
          }
        }
        state.show = true;
        return;
      }
    );
    onMounted(() => {
      setInterval(loadWallet, 4000);
    });

    const isActive = (navName) => {
      return state.curNavName == navName ? "flex-li navActive" : "flex-li";
    };
    const goToPage = (url) => {
      state.curNavName = url;
      globals.goToPage(url);
    };
    const onClickOutside = () => {
      state.showDropdown = false;
    };
    const signout = () => {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      globals.store.commit("resetUserInfo");
      localStorage.openid = null;
      globals.router.replace("/signin");
    };
    const to = (e) => {
      state.onli = e;
      switch (e) {
        case 3:
          window.open('https://work.weixin.qq.com/kfid/kfc5784144a1fe05fe8');
          break;
        default:
          globals.goToPage(state.urls[e]);
          break;
      }
    };
    const onSumbitOrder = (id) => {
      if (id == null) {
        state.showOptionDialog = false;
        return;
      }
      state.showOptionDialog = false;
      state.itemId = id;
      state.showPay = true;
    };
    const onClosePay = (res) => {
      state.showPay = false;
      if (res == null || !res.rc) {
        globals.post('app-order/over-topup-order/' + state.itemId, {}, () => { })
        return;
      }
      showDialog({
        title: "提示",
        message: "充值成功",
      });
    };
    const loadWallet = () => {
      if (window.location.href.indexOf('signin') > -1 || globals.store.state.userinfo.token == "") {
        state.isLogin = false;
        return;
      }
      globals.get('app-query/user-amount', {}, data => {
        if (data.rc) {
          state.isLogin = true;
          state.wallet = data.data;
          globals.store.state.userinfo.wallet = data.data;
        } else {
          state.isLogin = false;
        }
      });
    };
    const showWallet = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 625) {
        return false;
      }
      return state.isLogin;
    }
    return {
      ...toRefs(state),
      isActive,
      goToPage,
      onClickOutside,
      signout,
      to,
      onSumbitOrder,
      onClosePay,
      showWallet,
    };
  },
};
</script>

<style scoped>
.h-60px {
  height: 60px;
  background: #4160AB;
}

.bg-main-red {
  background-color: rgba(255, 255, 255, 0.9);
}

ul {
  margin: 10px;
}

.flex-li {
  /* border: 1px solid; */
  color: #3a8ac9;
  font-weight: 600;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.avatar {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: white;
  position: relative;
  text-align: center;
}

.dropdown {
  position: absolute;
  top: 39.5px;
  right: -20px;
  color: black;
  border: 1px solid rgb(239, 239, 245);
  animation: showDropdown 0.5s;
  background-color: white;
}

.dropdown-li {
  color: black;
  width: 80px;
  height: 35px;
  border-top: 1px solid rgb(239, 239, 245);
  text-align: center;
  line-height: 35px;
  padding: 5px;
  font-size: 16px;
}

.dropdown:nth-child(1) {
  border-top: 0px solid;
}

.fade-enter-active {
  animation: fadeShow 0.3s 1;
}

.fade-leave-active {
  animation: fadeHide 0.2s 1;
}

.navActive {
  background-color: #35bcea;
  border-radius: 5px;
  color: white;
}

.wallet {
  color: white;
  border-radius: 5px;
  background: red;
  min-width: 85px;
  text-align: center;
  padding: 0;
}

.wallet:hover {
  background: rgb(245, 87, 87);
}

.default-avatar-gb {
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0);
}

.active-avatar-gb {
  border-radius: 50%;
  border: 3px solid rgba(62, 129, 193, 0.3);
}

@keyframes fadeShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeHide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 625px) {
  li {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
  }

  .hide-home-text {
    display: none;
  }

  .title-logo-offset {
    margin-left: 5px
  }
}

@media screen and (min-width: 625px) {
  li {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
  }

  .title-logo-offset {
    margin-left: 30px
  }

}
</style>