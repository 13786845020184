<template>
  <div class="share max-w mx-auto w-90vw flex-center">
    <div class="text-center h-w-50px">
      <van-icon style="line-height: 50px" name="gold-coin" size="38" color="#E29836" />
    </div>
    <div class="flex-auto">
      <div class="font-size-16px font-weight-600">
        推荐朋友购买享
        <span style="color: #ef5350">20%</span>
        返现
      </div>
      <div class="font-size-12px" style="color: #86827c">
        <span>√ 专属优惠 </span>
        <span style="margin-left: 5px">√ 实时到账
        </span>
        <span style="margin-left: 5px">√ 随时提现
        </span>
      </div>
    </div>
    <div class="h-50px w-70px">
      <div class="font-weight-600 toShare text-center h-30px " style="line-height: 30px" @click="goShare">去推荐</div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import globals from "@/utils/globals";

export default {
  name: "OrderVue",
  setup() {
    const state = reactive({});
    const goShare = () => {
      if (window.location.href.indexOf('user') > -1) {
        globals.router.push('share');
      } else {
        globals.router.push('user/share');
      }
    };
    return {
      ...toRefs(state),
      goShare,
    };
  },
};
</script>
<style scoped>
.share {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
  border-radius: 15px;
  background-color: #fdf5e9;
}

.toShare {
  border: 1px solid #efefee;
  display: inline-block;
  width: 60px;
  background-color: white;
  border-radius: 15px;
  font-size: 14px;
}

.toShare:hover {
  cursor: pointer;
  background-color: rgb(101, 174, 223);
  color: white;
}

.h-w-50px {
  height: 50px;
  line-height: 50px;
  width: 50px;
}

.w-70px {
  width: 70px;
}

.h-30px {
  height: 30px;
}

@media screen and (min-width: 375px) {
  .max-w {
    width: 90vw;
  }

  .margin-top-change {
    margin-top: -160px;
  }
}

@media screen and (min-width: 425px) {
  .item {
    width: 354px;
  }

  .max-w {
    width: 373px;
  }
}
</style>