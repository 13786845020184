<template>
  <div class="drop-down-list relative">
    <div
      @click="show = !show"
      v-clickOutside="close"
      class="flex-betw items-center padding-7px add-cursor"
      style="border: 1px solid rgb(232 232 236); border-radius: 4px"
    >
      <div class="" style="color: rgb(194 194 194)">全部状态</div>
      <div style="color: rgb(194 194 194)"><van-icon name="arrow-down" /></div>
    </div>
    <transition name="van-fade">
      <div
        class="absolute bgc-fff scrollbar"
        v-show="show"
        style="
          border: 1px solid rgb(232 232 236);
          border-radius: 4px;
          width: calc(100% - 2px);
          max-height: 200px;
          overflow: auto;
        "
      >
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div
            class="item flex items-center add-cursor"
            v-for="(item, index) in list"
            :key="index"
          >
            option {{ index + 1 }}
          </div>
        </van-list>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import globals from "@/utils/globals";

export default {
  name: "StateDropdownVue",
  props: {
    content: {
      type: Array,
      default: ()=> [],
    },
  },
  setup(props) {
    const state = reactive({
      show: false,
      loading: false,
      finished: false,
      list: props.content,
    });
    onMounted(() => {
      console.log(globals);
      console.log(state.list);
      // onLoad();
      state.finished = true;
      // globals.showLoading();
    });
    const onLoad = () => {
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          state.list.push(state.list.length + 1);
        }
        state.loading = false;
        if (state.list.length >= 40) {
          state.finished = true;
        }
      }, 1000);
    };
    const close = ()=>{
      state.show = false;
    };
    return {
      ...toRefs(state),
      onLoad,
      close
    };
  },
};
</script>
<style scoped>
.drop-down-list {
  width: 100%;
}
.item {
  border-radius: 4px;
  padding: 10px;
  background-color: #f3f3f5;
  margin: 5px;
}
</style>