const clickOutside = () => {
  return {
    mounted: (el, binding) => {
      const eventHandler = (e) => {
        if (el.contains(e.target)) {
          return false;
        }
        // 如果绑定的参数是函数，正常情况也应该是函数，执行
        if (binding.value && typeof binding.value === "function") {
          binding.value(e);
        }
      };
      el.__click_outside__ = eventHandler;
      document.addEventListener("click", eventHandler);
    },
    beforeUnmount: (el) => {
      document.removeEventListener("click", el.__click_outside__);
      delete el.__click_outside__;
    },
  }
}

export default {clickOutside};
