<template>
  <div class="drop-down-list relative">
    <div @click="show = !show" v-clickOutside="close" class="flex-betw items-center padding-7px add-cursor"
      style="border: 1px solid rgb(232 232 236); border-radius: 4px">
      <div class="" :style="{ 'color': name == '' ? 'rgb(194 194 194)' : '#000' }">{{ name || '请选择一张车票' }}</div>
      <div style="color: rgb(194 194 194)"><van-icon name="arrow-down" /></div>
    </div>
    <transition name="van-fade">
      <div class="absolute bgc-fff scrollbar" v-show="show" style="
              border: 1px solid rgb(232 232 236);
              border-radius: 4px;
              width: calc(100% - 2px);
              max-height: 200px;
              overflow: auto;
            ">
        <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"
          :immediate-check="false">
          <div class="item add-cursor" v-for="(item, index) in list" :key="index" @click="onClick(item)">
            {{ item.product_name + "(" + item.productoption_name + ")" }}
            <div style="font-size: 12px;color: #b2b0b0;">{{ item.created || '' }}</div>
          </div>
        </van-list>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import globals from "@/utils/globals";

export default {
  name: "StateDropdownVue",
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClick'],
  setup(props, { emit }) {
    const state = reactive({
      show: false,
      loading: false,
      finished: false,
      list: props.content,
      name: "",
    });
    onMounted(() => {
      state.finished = true;
      loadRecords();
    });
    const loadRecords = () => {
      globals.get('app-query/order-list', {
        size: 999,
      }, (data) => {
        state.list = data.pagedata.data;
      });
    };
    const onLoad = () => {
      setTimeout(() => {
        state.loading = false;
      }, 1000);
    };
    const onClick = (e) => {
      state.name = e.product_name + "(" + e.productoption_name + ")";
      emit("onClick", e);
    };
    const close = () => {
      state.show = false;
    };
    return {
      ...toRefs(state),
      onLoad,
      close,
      onClick,
    };
  },
};
</script>
<style scoped>
.drop-down-list {
  width: 100%;
}

.item {
  border-radius: 4px;
  padding: 10px;
  background-color: #f3f3f5;
  margin: 5px;
}
</style>