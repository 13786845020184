import { createStore } from 'vuex';
const getDefaultStateUserInfo = () => {
  return {
    accessToken: '',
    nickname: '游客',
    ip: '0.0.0.0',
    signd: '未登录',
    uid: 0,
    openid: null,
    token: '',
    head_path: 'https://pic1.zhimg.com/80/v2-6afa72220d29f045c15217aa6b275808_720w.webp?source=1940ef5c',
    email: '',
  }
}

export default createStore({
  state: {
    userinfo: getDefaultStateUserInfo(),
    loading: false,
    openid: "",
    signinAfter: "/",
  },
  getters: {
    getSigninAfter(state) {
      if (state.signinAfter == "-lock-") {
        return "/";
      }
      return state.signinAfter;
    }
  },
  mutations: {
    setCode(state, data) {
      state.code = data;
    },
    setUserInfo(state, data) {
      state.userinfo = data;
      state.openid = data.openid;
    },
    resetUserInfo(state) {
      Object.assign(state.userinfo, getDefaultStateUserInfo())
    },
    setSigninAfter(state, url) {
      if (url == null || url == "") {
        return;
      }
      if (state.signinAfter != "/") {
        state.signinAfter = "-lock-";
        return;
      }
      if (url == "giftCard") {
        state.signinAfter = "/user/giftCard";
      } else if (url == "accounts") {
        state.signinAfter = "/user/my-accounts";
      } else if (url == "support") {
        state.signinAfter = "/user/support/new";
      } else {
        state.signinAfter = "/" + url;
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
