<template>
  <div class="custom-payCode flex-center">
    <van-dialog v-model:show="payCodeShow" title="微信二维码" :show-cancel-button="true" :show-confirm-button="false" @cancel="close" style="text-align: center;">
      <img v-if="value>0" :src="url+value" />
    </van-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import globals from "@/utils/globals";

export default {
  name: "payCodeVue",
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["onwxClose"],
  setup(props,{emit}) {
    const state = reactive({
      payCodeShow: true,
      value: props.modelValue,
      url: window.roots + "/app-wechat-pay/nativeCode/"
    });
    onMounted(() => {
      console.log(globals);
    });
    const close = () => {
      state.payCodeShow = false;
      globals.hideLoading();
      emit("onwxClose");
    }
    return {
      ...toRefs(state),
      close,
    };
  },
};
</script>
<style scoped>
</style>