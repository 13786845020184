import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/styles/base.css';
import '@/styles/anime.css';
import {
    Toast,
    Skeleton,
    SkeletonTitle,
    SkeletonImage,
    SkeletonAvatar,
    SkeletonParagraph,
} from 'vant';
import jweixin from 'weixin-js-sdk';
// import 'amfe-flexible';
import NavBar from '@/components/NavBar'
import ToShare from '@/components/ToShare'
import ProductDialog from '@/components/ProductDialog'
import StateDropdown from '@/components/StateDropdown'
import WorkOrderDropdown from '@/components/WorkOrderDropdown'
import PayBox from '@/components/PayBox'
import Loading from '@/components/Loading'
import echarts from './utils/echarts';
import customDirectives from './utils/customDirectives'
import VerifyBtn from '@/components/VerifyBtn.vue'

const app = createApp(App);
app.component("NavBar", NavBar);
app.component("ToShare", ToShare);
app.component("ProductDialog", ProductDialog);
app.component("StateDropdown", StateDropdown);
app.component("WorkOrderDropdown", WorkOrderDropdown);
app.component("CustomLoading", Loading);
app.component("PayBox", PayBox);
app.component("VerifyBtn", VerifyBtn);
app.use(Toast);
app.config.globalProperties.$echarts = echarts;
app.directive('clickOutside', customDirectives.clickOutside());
app.use(store).use(router).use(Vant).use(jweixin).mount('#app')
app.use(Skeleton);
app.use(SkeletonTitle);
app.use(SkeletonImage);
app.use(SkeletonAvatar);
app.use(SkeletonParagraph);
